<template>
  <div class="container">
    <PublicHeader :totalName="$t('property').projectorder" />
    <div class="content">
      <div class="name">{{ orderInfo.ProductName }}</div>
      <div class="item">{{ $t('property').orderNo }}：<span>{{ orderInfo.OrderNo }}</span></div>
      <div class="item">{{ $t('property').allRate }}：<span>{{ orderInfo.Rate }}%</span></div>
      <div class="item">{{ $t('property').tsammount }}：<span>{{ orderInfo.Amount }} USDT</span></div>
      <div class="item">{{ $t('property').allProject }}：<span>{{ orderInfo.Profit }} USDT</span></div>
      <div class="item">{{ $t('property').allEnd }}：<span>{{ orderInfo.Amount + orderInfo.Profit }} USDT</span></div>
      <div class="item">{{ $t('property').strattime }}：<span>{{ orderInfo.CreateTime | getLocalTime }}</span></div>
      <div class="item">{{ $t('property').enddtime }}：<span>{{ orderInfo.EndTime | getLocalTime }}</span></div>
      <div class="item">{{ $t('property').teCantr }}：
        <div class="txt"
          @click="$router.push({ name: 'Contract', query: { Name: userInfo.RealName, Amount: orderInfo.Amount, Term: orderInfo.Days, Time: orderInfo.CreateTime } })">
          <img src="@/assets/img/order_s.png" width="12" />
          {{ $t('property').btnSee }}
        </div>
      </div>
      <div class="btn" v-if="orderInfo.Status == 'Wait'" @click="showStop = true">{{ $t('property').tostopTs }}</div>
      <div class="msg" v-if="orderInfo.Status == 'Complete'">{{ $t('property').completed }}</div>
      <div class="msg" v-if="orderInfo.Status == 'Cancel'">{{ $t('property').canceled }}</div>
    </div>
    <!-- 中止托管 -->
    <van-popup v-model="showStop" closeable round :style="{ width: '320px', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('property').tips }}</div>
        <div class="info">
          <div class="address">{{ $t('property').stopMsg }}</div>
          <div class="btns">
            <div class="btn" @click="StopTsOrder">{{ $t('public').confirm }}</div>
            <div class="btn2" @click="showStop = false">{{ $t('public').cancel }}</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>

</template>

<script>
import userApi from '@/api/user'
import orderApi from '@/api/order'
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeader'

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      userInfo: {},
      orderInfo: {},
      showStop: false,
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    if (this.$route.query.Id) {
      this.GetTsOrderDetail(this.$route.query.Id)
    }
    this.getAccountBase()
  },
  methods: {
    // 获取用户信息
    async getAccountBase() {
      const res = await userApi.getAccountBase()
      this.userInfo = res.Data
    },
    // 获取订单详情
    async GetTsOrderDetail(Id) {
      const res = await orderApi.GetTsOrderDetail({ Id: Id })
      this.orderInfo = res.Data
    },
    // 中止托管订单
    async StopTsOrder() {
      await orderApi.StopTsOrder({ Id: this.orderInfo.Id })
      this.$toast(this.$t('property').stopSuccess)
      this.GetTsOrderDetail(this.orderInfo.Id)
      this.showStop = false
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 65px 13px 0 13px;
  background: #F6F6F6;

  .content {
    height: 401px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 13px;

    .name {
      font-weight: bold;
      font-size: 17px;
      color: #333333;
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;
      font-size: 15px;
      color: #333333;

      span {
        font-weight: bold;
      }

      .txt {
        font-weight: bold;
        font-size: 15px;
        color: #C92B2E;
      }
    }

    .btn {
      margin: 30px 34% 0 34%;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #707070;
    }

    .msg {
      margin-top: 50px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      color: #C92B2E;
    }

  }

  .popInfo {
    .title {
      padding: 20px 0;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      border-bottom: 1px solid #DCDCDC;
    }

    .info {
      padding: 15px 13px 20px 13px;
      text-align: center;

      .address {
        margin: 40px 0;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        line-height: 25px;
        overflow-wrap: break-word;
      }

      .tip {
        padding: 10px 0 0 0;
        font-size: 14px;
        color: #FF9B37;
      }

      .btns {
        margin: 20px 13px 0 13px;
        display: flex;
        justify-content: space-between;

        .btn {
          width: 119px;
          height: 40px;
          background: rgba(201, 43, 46, 0);
          border: 1px solid #C92B2E;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          color: #C92B2E;
        }

        .btn2 {
          width: 119px;
          height: 40px;
          background: #C92B2E;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          color: #fff;
        }
      }
    }

    .gradeInfo {
      padding: 20px 25px;

      .tit {
        font-weight: bold;
        font-size: 13px;
        color: #333333;

      }

      .item {
        margin-top: 17px;
        display: flex;
        align-items: center;

        .rod {
          margin-right: 5px;
          width: 3px;
          height: 3px;
          background: #FF8427;
          border-radius: 1px;
        }

        .rod1 {
          margin-right: 5px;
          width: 3px;
          height: 3px;
          background: #8C06FF;
          border-radius: 1px;
        }

        .txt {
          margin: 5px 0 0 10px;
          font-size: 13px;
          color: #666666;
        }
      }

      .tips {
        margin: 7px 0 0 35px;
        font-weight: bold;
        font-size: 13px;
        color: #FF8427;
      }

    }
  }

}
</style>
